@import '../../assets/scss/general';

.container {
	background: $primary-color;
	padding: $padding;
	margin-bottom: 2 * $padding;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;

	.subHeading {
		font-size: 1em;
	}

	.heading {
		color: $primary;
		font-size: 1.4em;
	}

	&.loading {
		.heading,
		.subHeading {
			height: 10px;
			width: 80%;
			margin: 4px 0;
			@extend .animated-background;
		}

		.heading {
			width: 60%;
			height: 15px;
		}

		.content {
			.line {
				height: 10px;
				width: 100%;
				margin: 4px 0;
				@extend .animated-background;

				&.last {
					width: 60%;
				}
			}
		}
	}

	@keyframes placeHolderShimmer {
		0% {
			background-position: -468px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}

	.animated-background {
		animation-duration: 1.25s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: #d6d6d6;
		background: linear-gradient(to right, #d6d6d6 8%, #cacaca 18%, #d6d6d6 33%);
		background-size: 800px 104px;
		height: 15px;
		position: relative;
	}
}
