@import '../../assets/scss/general';

.developedBy {
	text-align: center;
	margin-top: $padding;
	.club {
		height: 40px;
		margin-right: 2 * $padding;
	}
	.developer {
		border-left: 1px solid $primary-color;
		padding-left: 2 * $padding;
		height: 40px;
	}
}
