@import '../../assets/scss/general';

.container {
	background: $primary-color;
	padding: 2 * $padding;
	margin-bottom: 2 * $padding;

	color: $primary;
	font-size: 1.4em;
	text-align: center;
	cursor: pointer;

	&__primary {
		background: $primary;
		color: $primary-color;
	}

	&__secondary {
		background: $secondary;
		color: $secondary-color;
	}
	&__tertiary {
		background: $tertiary;
		color: $tertiary-color;
	}
	&__error {
		background: $error;
		color: $error-color;
	}

	&__small {
		padding: $padding;
		margin-bottom: 0;
	}

	.loader {
		width: 1.4em;
		height: 1.4em;

		animation-name: spin;
		animation-duration: 1000ms;
		animation-iteration-count: infinite;
		animation-timing-function: ease-in-out;
	}

	.subtitle {
		display: block;
		font-size: 0.8rem;
	}

	&.disabled {
		background: #6e6e6e;
		cursor: not-allowed;
	}
}

@keyframes spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
