@import '../../assets/scss/general';

$toplineHeight: 40px;
$subMenuHeight: 30px;

.wrapper {
	display: flex;
	flex-direction: column;
	justify-content: space-between;

	background: $primary-dark;
	height: 100%;
	min-height: calc(
		100vh - (env(safe-area-inset-bottom) + env(safe-area-inset-top))
	);
	width: 100vw;
	margin: 0 auto;

	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);
	padding-bottom: env(safe-area-inset-bottom);

	.header {
		position: sticky;
		top: 0;
		padding-top: env(safe-area-inset-top);

		background: $primary;
		color: $primary-color;
		z-index: 1200;

		.topLine {
			display: flex;
			align-items: center;
			justify-content: space-between;

			height: $toplineHeight;
			padding: $padding;
			text-transform: uppercase;
			font-weight: bold;

			.icon {
				font-size: 30px;
				cursor: pointer;
			}

			.pageTitle {
				font-size: 20px;
			}
		}

		.subMenu {
			height: $subMenuHeight;
			background: $secondary;
			color: $primary-color;
			margin: 0;
			padding: 0;
			list-style: none;
			display: flex;
			align-items: center;
			flex-wrap: nowrap;
			justify-content: flex-start;

			overflow-y: scroll;

			scrollbar-width: none;
			-ms-overflow-style: none;
			::-webkit-scrollbar {
				display: none; /* Chrome Safari */
			}

			.item {
				margin: 0 $padding;
				text-transform: uppercase;
				cursor: pointer;

				&.active {
					font-weight: bold;
				}
			}
		}
	}

	.content {
		padding: $padding;
		$withoutSubMenuHeight: $toplineHeight + 4 * $padding;
		width: calc(100vw - #{2 * $padding});
		overflow-x: hidden;
		min-height: 100%;
		z-index: 50;

		&.withSubMenu {
			$withSubMenuHeight: $withoutSubMenuHeight + $subMenuHeight;
			min-height: 100%;
		}
	}
}
