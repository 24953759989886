@import '../assets/scss/general';

.grid {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: $padding;

	@media screen and (min-width: 600px) {
		grid-template-columns: 1fr 1fr;
	}

	@media screen and (min-width: 1000px) {
		grid-template-columns: 1fr 1fr 1fr;
	}
}

.maxWidth {
	max-width: 600px;
	margin: 0 auto;
}
