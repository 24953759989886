@import '../../assets/scss/general';

.container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	align-items: center;
	text-align: center;
	color: $primary-color;

	.logo {
		max-width: 200px;
	}

	.heading {
		color: $primary-color;
		margin-top: 0;
	}
}
