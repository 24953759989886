@import '../../assets/scss/general';

.map {
	margin: -$padding;
	height: calc(100vh + #{2 * $padding});
	width: calc(100vw + #{2 * $padding});

	.popup {
		:global(.leaflet-popup-content-wrapper) {
			border-radius: 0;
		}
	}
}
