@import '../../assets/scss/general';

.container {
	position: fixed;
	top: env(safe-area-inset-top);
	right: -50vw;
	height: calc(100% - #{2 * $padding});
	width: calc(50vw - #{2 * $padding});
	background: $primary-color;
	z-index: 1600;
	padding: $padding;

	overflow: scroll;
	transition: all 0.2s ease-in;

	.image {
		width: 100vw;
		height: 56vw;
		max-height: 50vh;
		object-fit: cover;
		margin: -#{$padding} -#{$padding} 0 -#{$padding};

		&.logo {
			object-fit: contain;
			background: $primary;
		}
	}

	.heading {
		position: sticky;
		top: -#{$padding}; // + env(safe-area-inset-top));
		background: $primary;
		color: $primary-color;
		margin: -#{$padding} -#{$padding} $padding -#{$padding};
		padding: $padding;
	}

	.closeIcon {
		position: fixed;
		top: calc(#{$padding} + env(safe-area-inset-top));
		right: -50px;
		color: $primary-color;
		font-size: 30px;
		cursor: pointer;
		z-index: 1000;
		transition: all 0.5s ease-in;
	}

	.content {
		max-width: 600px;
		margin: 0 auto;
		background: $primary-color;
		margin-bottom: env(safe-area-inset-bottom);
		padding-bottom: env(safe-area-inset-bottom);
	}

	&__show {
		right: 0;
		width: calc(100vw - #{2 * $padding});
		.closeIcon {
			right: $padding;
		}
	}

	&__noContent {
		background: $primary-dark;
		.closeIcon {
			right: -20vw;
		}
	}
}
