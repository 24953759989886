@import '../../assets/scss/general';

.container {
	background: $primary-color;
	padding: $padding;
	margin-bottom: $padding;
	cursor: pointer;

	.image {
		height: 56vw;
		max-height: 40vh;
		width: calc(100% + #{2 * $padding});
		margin: -#{$padding} -#{$padding} $padding -#{$padding};

		object-fit: cover;

		&.logo {
			object-fit: contain;
			background: $primary;
		}
	}

	.heading {
		color: $primary;
		font-size: 1.2em;
		display: block;
	}

	.subtitle {
		display: block;
		font-size: 0.8em;
	}

	.content {
		margin-top: $primary;
	}

	&.loading {
		.image {
			height: 56vw;
			max-height: 40vh;
			width: calc(100% + #{2 * $padding});
			margin: -#{$padding} -#{$padding} $padding -#{$padding};
			@extend .animated-background;
		}

		.heading,
		.subtitle,
		.content {
			height: 10px;
			width: 60%;
			margin: 4px 0;
			@extend .animated-background;
		}

		.heading {
			width: 60%;
			height: 15px;
		}
		.subtitle {
			width: 80%;
		}
		.content {
			width: 100%;
		}
	}

	@keyframes placeHolderShimmer {
		0% {
			background-position: -468px 0;
		}
		100% {
			background-position: 468px 0;
		}
	}

	.animated-background {
		animation-duration: 1.25s;
		animation-fill-mode: forwards;
		animation-iteration-count: infinite;
		animation-name: placeHolderShimmer;
		animation-timing-function: linear;
		background: #d6d6d6;
		background: linear-gradient(to right, #d6d6d6 8%, #cacaca 18%, #d6d6d6 33%);
		background-size: 800px 104px;
		position: relative;
	}

	&__slider {
		margin-bottom: 0;
		.heading {
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
		}
	}
}
