@import '../../assets/scss/general.scss';

.wrapper {
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	flex-direction: column;
	background: $primary;

	position: fixed;
	top: 0;
	left: -50vw;
	z-index: 1500;

	width: 50vw;
	height: 100vh;
	overflow-y: scroll;

	transition: all 0.2s ease-in;

	padding-left: env(safe-area-inset-left);
	padding-right: env(safe-area-inset-right);

	&__show {
		left: 0;
		width: 100vw;
	}

	.header {
		text-align: center;
		.logo {
			padding: 40px 10%;
			width: 80%;
			max-width: 400px;
		}
	}

	.container {
		list-style: none;
		padding: 0;

		.item {
			cursor: pointer;
			color: $primary-color;

			text-decoration: none;

			&:visited {
				text-decoration: none;
			}
		}

		&.main {
			margin-bottom: 40px;

			.item {
				display: block;
				text-transform: uppercase;
				padding: 15px 30px;
				text-decoration: none;
				transition: all 0.2s ease-in;
				font-weight: bold;

				&:hover,
				&.active {
					background: $primary-color;
					color: $primary;
				}
			}
		}

		&.legal {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			.item {
				padding: $padding;
				&.active {
					font-weight: bold;
				}
			}
		}
	}
}
