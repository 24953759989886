@import '../../assets/scss/general';

.heading {
	margin-top: 50px;
	color: $primary-color;

	&__primary {
		color: $primary;
	}
	&__secondary {
		color: $secondary;
	}

	&__tertiary {
		color: $tertiary;
	}
}
