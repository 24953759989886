$primary: #034890;
$primary-dark: darken($primary, 20%);
$primary-dark-5: darken($primary, 5%);
$primary-color: #ffffff;

$secondary: #0085cd;
$secondary-dark: darken($secondary, 20%);
$secondary-dark-5: darken($secondary, 5%);
$secondary-color: #ffffff;

$tertiary: #fdc300;
$tertiary-color: #000000;

$error: #cc071e;
$error-dark: darken($error, 20%);
$error-dark-5: darken($error, 5%);
$error-color: #ffffff;

$success: #7d9f44;
$success-dark: darken($success, 20%);
$success-dark-5: darken($success, 5%);
$success-color: #ffffff;

$padding: 10px;
