@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@200;400;700&display=swap');
@import './assets/scss/general.scss';

:root {
	--swiper-theme-color: #034890 !important;
	-webkit-overflow-scrolling: touch;

	&::-webkit-scrollbar {
		display: none; // Safari and Chrome
	}
}

body {
	background: $primary-dark;
	margin: 0;
	font-family: 'Nunito', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: $primary;
}

a {
	color: $primary;
	text-decoration: none;

	&:hover,
	&:focus,
	&:visited {
		color: $primary;
	}
}

.ptr .lds-ellipsis div {
	background: $primary-color;
}

.swal-modal {
	border-radius: 0;

	.swal-icon {
		&--error {
			border-color: $error;

			&__line {
				background-color: $error;
			}
		}

		&--success {
			border-color: $success;

			&__line {
				background-color: $success;
			}
		}
	}

	.swal-button {
		padding: 7px 19px;
		border-radius: 0;
		background-color: $secondary;
		font-size: 12px;
		border: 1px solid $secondary-dark;
		text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.3);

		&:not([disabled]):hover {
			background-color: $secondary-dark-5;
		}

		&--confirm {
			background-color: $primary;
			&:not([disabled]):hover {
				background-color: $primary-dark-5;
			}
		}

		&--exit {
			background-color: $secondary;
			&:not([disabled]):hover {
				background-color: $secondary-dark-5;
			}
		}
	}
}
